<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-binvoice-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("SALES_INVOICES.SALES_INVOICES_LIST") }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addSalesInvoice()"
                v-if="$currentUserCan($permissions.PERM_CREATE_SALES_INVOICES)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("SALES_INVOICES.ADD_SALES_INVOICE") }}
                </span>
              </base-button>

              <notification-subscription
                v-if="$currentUserCan($permissions.PERM_VIEW_SALES_INVOICES)"
                :objectType="'sales-invoices'"
                :events="{
                  CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>

        <sales-invoice-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesInvoiceListTable from "./partials/SalesInvoiceListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SalesInvoiceListTable,
    NotificationSubscription,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  methods: {
    addSalesInvoice() {
      this.$router.push({ name: "Add SalesInvoice" });
    },
  },
};
</script>
